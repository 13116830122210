<style>
   .tooltip_ensayo:hover:after {
    background: transparent !important;
}
.tooltip_ensayo {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip_ensayo .tooltiptext_ensayo {
    font-size: 12px;
    visibility: hidden;
    width: 200px;
    background-color: white;
    border: 1px solid #707070;
    color: #666666;
    text-align: left;
    padding: 15px 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: -8px;
    left: -210px;
}

.tooltip_ensayo:hover .tooltiptext_ensayo {
    visibility: visible;
}
.py-0{
    padding-bottom: 0 !important;
     padding-top: 0 !important;
}
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 4px;
}
.v-input__slot {
 justify-content: center;
}
</style>
<template>
<!--eslint-disable -->
    <div class="text-center">
    <v-dialog
      v-model="dialogensayo"
      width="650"
    >
      <v-card>
        <v-card-title class="align-items-baseline">
          <div>
            <p class="fstitulomodal mb-0 bold">{{ $t('solicitudes.title_ensayo') }}</p>
            <div class="d-flex">
                <p class="mb-0 c-azul2 fs14 bold mr-4">Food</p>
                <p class="mb-0 c-azul2 fs14">{{ $t('solicitudes.muestra') }}: 1</p>
            </div>
          </div>

          <v-spacer></v-spacer>

          <a icon @click="dialogensayo = false">
            <v-icon>mdi-close</v-icon>
          </a>
        </v-card-title>

        <v-card-text>
            <v-data-table
                :headers="headerensayo"
                :items="EnsayosDataStore[matriz_id]"
                fixed-header
                height="381px"
                hide-default-footer
            >
              <template v-slot:item.check="{ item }">
                  <v-checkbox 
                    style="justify-content: center;" 
                    :key="item.id" 
                    value 
                    v-model="item.ensayos_premuestra != null"
                  ></v-checkbox>
              </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 ma-2 c-btn c-btn--primary"
            color="indigo darken-3"
            @click="dialogensayo = false"
          >
            {{ $t('ensayos.guardar') }}
          </v-btn>

          <v-btn
            color="black"
            class="ma-2 white--text"
            @click="dialogensayo = false"
          >
            {{ $t('ensayos.cancelar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';
// import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('solicitudesStore',['EnsayosDataStore']),
    headerensayo() {
        const itemsheader = [{
            text: i18n.t('solicitudes.n_ensayo'),
            align: 'start',
            sortable: false,
            value: 'ensayo_es',
        },{
            text: i18n.t('solicitudes.desc'),
            align: 'start',
            sortable: false,
            value: 'descripcion',
        },{
            text: i18n.t('solicitudes.revisar'),
            align: 'start',
            sortable: false,
            value: 'check',
        }];
        return itemsheader;
    },
  },
  data: () => ({
    matriz_id : null,
    muestra_preot_id : null,

    // ensayosData : {},
      checkbox: true,
    dialogensayo: false,
    ensayos: [
          {
            ensayo_es: 'Frozen Yogurt',
            descripcion: 159,
          },
        ],
  }),
  methods: {
    init(matriz_id, muestra_preot_id) {
      this.matriz_id = matriz_id
      this.muestra_preot_id = muestra_preot_id

      console.log({matriz_id:matriz_id})
      console.log({muestra_preot_id:muestra_preot_id})

      this.dialogensayo = true;
      // this.ensayosData = this.varEnsayosData;
      // console.log({ 'this.ensayosData':this.ensayosData });


      // this.getEnsayos();
    },
    async getEnsayos(){
        const data = {
            // preot_id : this.preot_id,
            muestra_preot_id : this.muestra_preot_id
        }
        const data_set = await Service.apiToken('POST', 'getEnsayos', this.$ls.storage.token, data);
        console.log({'data_set':data_set});

        /*
        const ensayosXmatriz = _.groupBy(data_set.response, 'matriz_id');
        this.setEnsayosStore(ensayosXmatriz);
        /*data_set.map(function(item, key){
            Object.entries(ensayosXmatriz).forEach(([key2, item2]) => {
            // ensayosXmatriz.map(function(item2, key2){
                if( item.matriz_id == key2 ){
                    ensayosXmatriz[key2] = item;
                }
            });
        });*/
        // console.log({'ensayosXmatriz':ensayosXmatriz});
        
    },
  },
};
</script>
